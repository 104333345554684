(function () {
    'use strict';

    function PaymentMetricController($http, $scope, $location, $document, PaymentFormRequest, CountryModel, $mdDialog, $log, $translate, $timeout,
                                     PaymentInitRequest, TokenService, InstanceService, DetectInstanceService,
                                     JhiLanguageService, AccountService, PaymentsService, CurrencyService) {
        var vm = this;
        vm.protocol = $location.protocol();
        vm.paymentForm = new PaymentFormRequest();
        vm.paymentInit = new PaymentInitRequest();
        vm.countries = new CountryModel();
        vm.host = $location.host();
        vm.port = $location.port();
        vm.domain = vm.protocol + '://' + vm.host;
        vm.request = {};
        vm.currencies = [];
        vm.currency = '';
        vm.invoiceAllowed = true;
        vm.maxTokensNum  = 250;
        vm.instance = InstanceService.instance().lang;
        vm.detectedInstance = DetectInstanceService.instance();
        vm.isRomania = vm.detectedInstance === 'ro';
        vm.paymentForm.invoiceRequired = vm.isRomania;
        vm.romanianCounties = [];
        vm.romanianCities = [];

        vm.getStatusSms = getStatusSms;
        vm.isSmsNotValid = isSmsNotValid;
        vm.isPaymentDisabled = isPaymentDisabled;
        vm.showPaymentsMethods = showPaymentsMethods;
        vm.setCurrency = setCurrency;
        vm.setTypeMethod = setTypeMethod;
        vm.getCurrencies = getCurrencies;
        vm.resetSteps = resetSteps;
        vm.goStep = goStep;
        vm.buyTokens = buyTokens;
        vm.getPrice = getPrice;

        $scope.$on("langChanged",function () {
            setDefaultCurrency();
            getPrice();
        } );

        if (vm.isRomania) {
            $http.get('/assets/ro-counties-cities.json').then(function (response) {
                vm.romanianCounties = response.data.counties;
            });
        }

        vm.onRoCountyChange = function () {
            vm.paymentForm.invoice.city = '';
            getCities();
        };

        function getCities() {
            if (vm.paymentForm.invoice.county) {
                const selectedCounty = vm.romanianCounties.find(function (c) {
                    return c.name === vm.paymentForm.invoice.county;
                });
                vm.romanianCities = selectedCounty ? selectedCounty.cities : [];
            }
        }

        function isPaymentDisabled(paymentMethod){
            return vm.paymentForm.tokens > vm.paymentInit[paymentMethod].maxTokens || (paymentMethod !== 'TYPE_SMS' && anyValidStatusSMS());
        }

        function isSmsNotValid(value) {
            if(value){
                return vm.onSmsStatuses.codesNotValid.includes(value);
            } else {
                return false;
            }
        }

        function getStatusSms(code) {
            return vm.onSmsStatuses.statuses[code.name];
        }

        function anyValidStatusSMS() {
            var anyValid = false;
            angular.forEach(vm.onSmsStatuses.statuses, function (value, key) {
                if(value === 'valid'){
                    anyValid = true;
                }
            });
            return anyValid;
        }

        function showPaymentsMethods(){
            vm.paymentsMethodsList = '';
            angular.forEach(InstanceService.instance().currencies.settings, function(value, key) {
                vm.paymentsMethodsList += '<span class="payments-methods-list">';
                vm.paymentsMethodsList += '<span class="payment-method payment-method-currency">'+value.name+'</span>';
                angular.forEach(value.methodsOfPayment, function(paymentType, key) {
                	if(paymentType === 'TYPE_MOBILPAY_SMS' && vm.instance === 'ro'){
                		paymentType += '_RO';
                	}
                	vm.paymentsMethodsList += '<span class="payment-method '+paymentType+'"></span>';
                });
            vm.paymentsMethodsList += '</span>';
            });
            $mdDialog.show(
                  $mdDialog.confirm()
                  .clickOutsideToClose(true)
                  .htmlContent($translate.instant("payments.metric.paymentsmethodslist")+vm.paymentsMethodsList)
                  .ariaLabel('ok')
                  .ok('OK')
                  );
        }

        function setDefaultCurrency(){
            CurrencyService.getDefaultCurrency().then(
                function (defaultCurrency) {
                    vm.currency = defaultCurrency;
                    setPaymentMethod(vm.currency);
                    getPrice();
                    getPaymentMethods(vm.currency);
                }
            );
        }

        function setCurrency(currency){
            vm.currency = currency;
            getPaymentMethods(currency);
            vm.paymentForm.paymentMethod = '';
            setPaymentMethod(currency);
            getPrice();
        }

        function getCurrencies(){
            vm.currencies = InstanceService.instance().currencies.list;
            setDefaultCurrency();
        }

        function setPaymentMethod(currency){
            angular.forEach(InstanceService.instance().currencies.settings, function(value, key) {
                if( currency === value.name ) {
                    vm.paymentMethod = value.methodsOfPayment[0];
                }
            });
            vm.paymentForm.paymentMethod = vm.paymentMethod;
        }

        function getPaymentMethods(currency) {
            vm.paymentForm.paymentMethod = '';
            angular.forEach(InstanceService.instance().currencies.settings, function(value, key) {
                if( vm.currency === value.name ) {
                    vm.paymentsMethod = value.methodsOfPayment;
                    vm.invoice = value.invoice;
                }
            });
            removePaymentKassa24ByAvailableUsers(vm.paymentsMethod);
            setPaymentMethod(vm.currency);
        }

        //for testing payment purposes only
        function removePaymentEasyPayByAvailableUsers(paymentMethods){
        	var methods = [];

        	var loggedUserId = AccountService.account.id;
		    PaymentsService.getEasyPayAvailableForUsers().then(function(responseData){
		    	var ids = responseData.data;
		    	var easyPayAvailable = false;
		    	angular.forEach(responseData.data, function (value, key) {
		    		if(loggedUserId===value){
		    			easyPayAvailable = true;
		    		}
		    	});

		    	angular.forEach(paymentMethods, function (method, key) {
		    		if(method === 'TYPE_EASY_PAY'){
		    			if(easyPayAvailable){
		    				methods.push(method);
		    			}
		    		}else{
		    			methods.push(method);
		    		}
		    	});

		    });

		    vm.paymentsMethod = methods;
        }

        function removePaymentKassa24ByAvailableUsers(paymentMethods){
        	var methods = [];

        	var loggedUserId = AccountService.account.id;
		    PaymentsService.getKassa24AvailableForUsers().then(function(responseData){
		    	var ids = responseData.data;
		    	var paymentAvailable = false;

		    	if(ids){
			    	angular.forEach(responseData.data, function (value, key) {
			    		if(loggedUserId===value){
			    			paymentAvailable = true;
			    		}
			    	});
		    	}else{
		    		paymentAvailable = true;
		    	}

		    	angular.forEach(paymentMethods, function (method, key) {
		    		if(method === 'TYPE_KASSA_24'){
		    			if(paymentAvailable){
		    				methods.push(method);
		    			}
		    		}else{
		    			methods.push(method);
		    		}
		    	});

		    });

		    vm.paymentsMethod = methods;
        }

        function disableInputNumberDefaultBehavior() {
            var input = $document[0].getElementById("tokensNumber");
            input.addEventListener("wheel", function (event) {
                event.preventDefault();
            });
        }

        function setTypeMethod() {
            vm.invoiceAllowed = vm.paymentInit[vm.paymentForm.paymentMethod].invoice;
            if (!vm.invoiceAllowed) {
                vm.paymentForm.invoiceRequired = false;
            }
            vm.paymentMethod = vm.paymentForm.paymentMethod;
            getPrice();
        }

        function resetSteps() {
            vm.stepsActiveObj = {
                stepTokens: true,
                stepInvoice: false,
                stepPaymentMethod: false,
            };
        }

        function getTokensInfo() {
            TokenService.getInfo().then(function (responseData) {
                vm.availableTokens = responseData.data.availableTokens;
                vm.tokensNeeded = responseData.data.tokensNeeded;

                if (TokenService.buyTokens) {
                    if (TokenService.tokensNedded) {
                        vm.paymentForm.tokens = TokenService.tokensNedded;
                        vm.paymentForm.paymentForPriority = TokenService.buyPriority;
                        TokenService.buyTokens = false;
                        TokenService.buyPriority = false;
                        TokenService.tokensNedded = false;
                    } else {
                        vm.paymentForm.tokens = 1;
                        if (vm.tokensNeeded > vm.availableTokens) {
                            vm.paymentForm.tokens = Math.max(1, Math.min((vm.tokensNeeded - vm.availableTokens), vm.maxTokensNum));
                            TokenService.buyTokens = false;
                        }
                    }
                } else {
                    if (vm.tokensNeeded > vm.availableTokens) {
                        vm.paymentForm.tokens = Math.max(1, Math.min((vm.tokensNeeded - vm.availableTokens), vm.maxTokensNum));
                    } else {
                        vm.paymentForm.tokens = 3;
                    }
                }
                getPrice();
            });
        }

        function goStep(step) {
            resetSteps();
            vm.paymentForm.phase = step;
            if (step === 'tokens') {
                vm.stepsActiveObj.stepTokens = true;
            }
            if (step === 'invoice') {
                getPaymentMethods();
                getInvoiceInfo();
                vm.stepsActiveObj.stepInvoice = true;
            }
            if (step === 'paymentMethod') {
                vm.stepsActiveObj.stepPaymentMethod = true;
            }
        }

        function validatePaymentRequest(request){
            if (vm.isRomania) {
                var foundCounty;
                if (vm.request && vm.request.invoice && vm.request.invoice.county) {
                    foundCounty = vm.romanianCounties.find(function (element) {
                        return element.name === vm.request.invoice.county;
                    });
                    if (!foundCounty) {
                        vm.request.invoice.county = null;
                        return false;
                    }
                }
                if (foundCounty && vm.request && vm.request.invoice && vm.request.invoice.city) {
                    const foundCity = foundCounty.cities.find(function (element) {
                        return element === vm.request.invoice.city;
                    });
                    if (!foundCity) {
                        vm.request.invoice.city = null;
                        return false;
                    }
                }
            }

            if( request.productQuantity > vm.paymentInit[vm.paymentForm.paymentMethod].maxTokens ) {
                $mdDialog.show(
                  $mdDialog.confirm()
                  .clickOutsideToClose(true)
                  .textContent($translate.instant("payments.metric.paymentmaxtokensinfo"))
                  .ariaLabel('ok')
                  .ok('OK')
                  );
                return false;
            } else{
                return true;
            }

        }

        function buyTokens() {
            paymentMethodResponse();
            vm.request.productQuantity = vm.paymentForm.tokens;
            vm.request.invoiceRequired = vm.paymentForm.invoiceRequired;
            vm.request.paymentMethod = vm.paymentForm.paymentMethod;
            if (!vm.paymentForm.isCompany) {
                vm.paymentForm.invoice.nip = null;
            }
            vm.request.invoice = vm.paymentForm.invoice;
            vm.request.paymentForPriority = vm.paymentForm.paymentForPriority;
            vm.request.currency = vm.currency;
            vm.request.documentId = vm.onDocumentId;
            vm.request.browserInfo = getBrowserInfo();
            if( validatePaymentRequest(vm.request) ) {
                vm.onInitPayment(vm.request);
            }
        }

        function getBrowserInfo() {
            const browserInfoModel = {};
            browserInfoModel.colorDepth = window.screen.colorDepth.toString();
            browserInfoModel.screenHeight = window.screen.height.toString();
            browserInfoModel.screenWidth = window.screen.width.toString();
            browserInfoModel.language = window.navigator.language;
            browserInfoModel.javaEnabled = window.navigator.javaEnabled();
            browserInfoModel.timeZone = (new Date()).getTimezoneOffset().toString();
            return browserInfoModel;
        }

        function paymentMethodResponse() {
            vm.request.userPaymentType = vm.paymentInit[vm.paymentForm.paymentMethod].userPaymentType;
            vm.request.backUrl = vm.domain + (vm.port ? (':' + vm.port) : '') + vm.paymentInit[vm.paymentForm.paymentMethod].backUrl;

            if (vm.paymentForm.paymentMethod === 'TYPE_SMS') {
                vm.request.smsCodes = [];
                angular.forEach(vm.paymentForm.smsCodes, function (value, key) {
                    if(vm.onSmsStatuses.statuses[value.name] !== 'valid' && !isSmsNotValid(value.code) ) {
                        vm.request.smsCodes.push(value);
                    }
                });
            } else if (vm.paymentForm.paymentMethod === 'TYPE_BLIK') {
                vm.request.blikCode = vm.paymentForm.blikCode;
            }
        }

        function preparePayment(){
            getCurrencies();
            getTokensInfo();
            resetSteps();
        }

        function getPrice() {
            var request = vm.paymentInit.price;
            request.quantity = vm.paymentForm.tokens;
            vm.tokenError = (!(vm.paymentForm.tokens > 0 && vm.paymentForm.tokens <= vm.maxTokensNum));

            if (angular.isUndefined(vm.paymentForm.tokens) || vm.paymentForm.tokens === '0' || vm.paymentForm.tokens === '') {
                request.quantity = 1;
            }

            request.userPaymentType = vm.paymentMethod;
            request.currency = vm.currency;

            if( vm.paymentForm.tokens !== '' || angular.isUndefined(vm.paymentForm.tokens)) {
                vm.onGetPrice(request);
            }
            vm.tokensArr = Array(parseFloat(request.quantity)).fill().map(function (e, i) {
                return i;
            });
     }

     function getInvoiceInfo() {
        vm.paymentForm.invoice = vm.onInvoiceInfo;
        getCities();
    }

    vm.nextStep = function() {
        if (vm.paymentForm.phase === 'stepTokens') {
            if (vm.invoice) {
                goStep('invoice');
                return;
            } else {
                goStep('paymentMethod');
                return;
            }
        }
        if (vm.paymentForm.phase === 'invoice') {
            goStep('paymentMethod')
        }
    }

    vm.goBack = function () {
        if (vm.paymentForm.phase === 'paymentMethod') {
            if (vm.invoice) {
              vm.goStep('invoice');
              return;
            } else {
              vm.goStep('stepTokens');
              return;
            }
        }
        if (vm.paymentForm.phase === 'invoice') {
            vm.goStep('stepTokens');
        }
    }

    preparePayment();
    disableInputNumberDefaultBehavior();
}

angular.module('plagiat2017App')
.component('paymentMetric', {
  templateUrl: 'app/account/payments/payment-metric/payment-metric.template.html',
  controller: PaymentMetricController,
  bindings: {
      onInitPayment: '<',
      onGetPrice: '<',
      onTokenPrice: '<',
      onInvoiceInfo: '<',
      onGetPaymentMethods: '<',
      onPaymentMethods: '<',
      onDocumentId: '<',
      onSmsStatuses: '=',
      onSmsNotValid: '='
  }
});

})();
